// extracted by mini-css-extract-plugin
export var Android = "Services-module--Android--33114";
export var arrowDiv = "Services-module--arrowDiv--5eaa4";
export var arrowImg = "Services-module--arrowImg--46d5a";
export var boxContent = "Services-module--boxContent--714a3";
export var btn = "Services-module--btn--4549c";
export var cards = "Services-module--cards--bc860";
export var cardsContent = "Services-module--cardsContent--92f2c";
export var cardsZ = "Services-module--cardsZ--df007";
export var djangoArrowImg = "Services-module--djangoArrowImg--1030c";
export var estabBtn = "Services-module--estabBtn--8d5b1";
export var explorrBtn = "Services-module--explorrBtn--f0780";
export var finImg = "Services-module--finImg--61f84";
export var heading = "Services-module--heading--216ab";
export var processSteps = "Services-module--processSteps--e6c6d";
export var product1 = "Services-module--product1--d3412";
export var ser = "Services-module--ser--cb510";
export var soft = "Services-module--soft--d0ceb";
export var softwareTabs = "Services-module--softwareTabs--27819";
export var tabImg = "Services-module--tabImg--8f35f";
export var tabbingCards = "Services-module--tabbingCards--d89a0";