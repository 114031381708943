import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Row from "react-bootstrap/Row"
import Tab from "react-bootstrap/Tab"
import "./Services.scss"
import * as styles from "./Services.module.scss"

const Services = ({ strapiData }) => {
  let defaultActiveTab = strapiData?.cards?.[0]?.title || "default"
  return (
    <div className={`${styles.softwareTabs} servicesProject`}>
      <Container>
        <div className={styles.Android}>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={defaultActiveTab}
          >
            <Row>
              <Col lg={6}>
                <p className={styles.ser}>Our Fixed-Price Services</p>
                <h2
                  className={styles.heading}
                  dangerouslySetInnerHTML={{ __html: strapiData?.title }}
                ></h2>
                <div className={styles.soft}>
                  <Nav variant="pills" className={`dedicatedSoftware`}>
                    {strapiData?.cards &&
                      strapiData?.cards?.map((e, i) => (
                        <Nav.Item key={i}>
                          <div className={styles.tabImg}>
                            <Nav.Link eventKey={`${e?.title}`}>
                              {e?.title}
                              <img
                                decoding="async"
                                loading="lazy"
                                alt="explore icon"
                                className="icon"
                                src="https://invozone-backend.s3.us-east-1.amazonaws.com/Vector_ecbdd64e38.svg"
                              />
                            </Nav.Link>
                          </div>
                        </Nav.Item>
                      ))}
                  </Nav>
                </div>
              </Col>
              <Col lg={6}>
                <div className="tabs">
                  <Tab.Content className="dedicatedReactTab">
                    {strapiData?.cards &&
                      strapiData?.cards?.map((e, i) => (
                        <Tab.Pane eventKey={`${e?.title}`} key={i}>
                          <div className={styles.boxContent}>
                            <img
                              src={e?.image1[0]?.localFile?.publicURL}
                              alt={e?.title}
                              loading="lazy"
                            />
                            <div className={"desc"}>
                              <h3>{e?.title}</h3>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: e?.subTitle,
                                }}
                              />
                            </div>
                            <Link to={"/contact-us/"} className={styles.btn}>
                              Explore More
                              <img
                                src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1_cc51769d02.svg"
                                decoding="async"
                                loading="lazy"
                                alt="explore icon"
                              />
                            </Link>
                          </div>
                        </Tab.Pane>
                      ))}
                  </Tab.Content>
                </div>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </Container>
    </div>
  )
}
export default Services
