import React from "react"
import { Link } from "gatsby"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./ServicesMobile.module.scss"

const ServicesMobile = ({ strapiData }) => {
  return (
    <div className="softwareDevelopmentAccordian">
      <div className={`${styles.softwareAccordianMobile}`}>
        <Container>
          <p className={styles.ser}>{strapiData?.title}</p>

          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
          ></h2>
          <Row className="gap-30">
            {strapiData?.cards &&
              strapiData?.cards?.map((e, i) => (
                <Col lg={6} md={6} className="d-flex">
                  <div className={styles.cards}>
                    <img
                      src={e?.image1[0]?.localFile?.publicURL}
                      alt={e?.title}
                      loading="lazy"
                    />
                    <h3>{e?.title}</h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: e?.subTitle,
                      }}
                    ></p>
                    <Link to={"/contact-us/"} className={styles.btn}>
                      Explore More
                      <img
                        src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1_cc51769d02.svg"
                        decoding="async"
                        loading="lazy"
                        alt="explore icon"
                      />
                    </Link>
                  </div>
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default ServicesMobile
