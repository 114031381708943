// extracted by mini-css-extract-plugin
export var Heading = "Banner-module--Heading--a7cf8";
export var bannerCon = "Banner-module--bannerCon--a60da";
export var bannerHeading = "Banner-module--bannerHeading--25682";
export var btn = "Banner-module--btn--06c5c";
export var description = "Banner-module--description--adc2b";
export var fintech = "Banner-module--fintech--8604d";
export var freshprepHeading = "Banner-module--freshprepHeading--6285c";
export var imageAnimation = "Banner-module--imageAnimation--2fbe0";
export var portfolioAppWorkBanner = "Banner-module--portfolioAppWorkBanner--3d2bc";
export var subTitle = "Banner-module--subTitle--90054";
export var topToBottomBounce = "Banner-module--topToBottomBounce--60e0d";